import React from 'react';
import '../../css/section2.css';
import {
	Container,
	Row,
	Col
} from 'react-bootstrap';

export default class Section2 extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
		};
	}

	componentDidMount() {
	}

	render() {
		return <section className="section-2" id="intro">
			<Container>
				<Row>
					<Col md={6}>
						<img src="images/idle-1.png" className="w100 mb50"/>
					</Col>
					<Col md={6}>
						<div className="d-none d-lg-block" style={{marginTop:'200px'}}></div>
						<p className="title">Внедрение современных инструментов торговли с доставкой</p>
						<p className="middle-text">Это позволит ознакомить своих клиентов с актуальным списком предлагаемых товаров, собрать корзину, уведомить вас, исполнителей и доставщиков о новом заказе, в удобном приложении, что мы разработали для всех участников цепи формирования и реализации заказов.</p>
					</Col>
				</Row>
			</Container>
		</section>;
	}
}