import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	// Link,
	// useRouteMatch,
	// useParams
} from 'react-router-dom';
import PageHome from './pages/home';
import {connect} from 'react-redux';

class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hasNoToken: true,
		};
	}

	componentDidMount() {
		this.setState({hasNoToken: false});
	}

	render() {
		return <Router>
			{this.state.hasNoToken?<span>Загрузка приложения...</span>:
				<React.Fragment>
					{this.props.preLoader?<div className="preLoader"><div style={{flex:'100%',marginTop:'calc(100% - 80px)'}}><div className="ldsRoller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div></div>:null}
					<Switch>
						<Route path={`${process.env.PUBLIC_URL}/about`}>
							{/*<PageViewOrderDetail/>*/}
						</Route>
						<Route path={`${process.env.PUBLIC_URL}/contact`}>
							{/*<PageLogout/>*/}
						</Route>
						<Route path={`${process.env.PUBLIC_URL}/`}>
							<PageHome/>
						</Route>
					</Switch>
				</React.Fragment>}
		</Router>
	};
}

const mapStateToProps = (state) => {
	return {
		preLoader: state.main.preLoader,
	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		setState: (obj) => dispatch({type: 'main/setState', payload: obj})
	}
};
export default connect(mapStateToProps, mapDispatchToProps)(App);