const host = (process.env.NODE_ENV === 'development')?'https://inlo.ru':'';

function Request(url, data = {}, signal) {
	let reqObj = {
		method: 'POST',
		signal: signal
	};

	if (typeof data === 'object')
	{
		let postData = new FormData();
		Object.keys(data).map(function (key, index) {
			return postData.append(key, data[key]);
		});

		reqObj.body = postData;
	}

	return new Promise((resolve, reject) => {
		let link = host+process.env.PUBLIC_URL+url;
		fetch(link, reqObj)
			.then(res => res.json())
			.then((result) => {
				if (result.status === 'ok')
					return resolve(result);
				return reject(result);
			})
			.catch((error) => {
				return reject(error);
			});
	});
};

module.exports.Request = Request;