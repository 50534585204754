import React from 'react';
import '../../css/section4.css';
import {
	Container,
	Row,
	Col
} from 'react-bootstrap';

export default class Section4 extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
		};
	}

	componentDidMount() {
	}

	render() {
		return <section className="section-4" id="features">
			{/*<Container>*/}
				<Row>
					<Col xl={6} md={12} style={{padding: 0}}>
						<img src="images/itemPack.png" className="w100"/>
					</Col>
					<Col xl={6} md={12} style={{padding: '80px 1.5rem 1.5rem 1.5rem'}}>
						<p className="title">Возможности</p>
						<Container style={{padding:0}}>
							<Row>
								<Col xs={4} sm={4} md={2} lg={2}>
									<div className="numCircle">1</div>
								</Col>
								<Col xs={8} sm={8} md={10} lg={10}>
									<p className="middle-text">Свои полноценные магазины. Вы можете создать один или несколько внутри своего приложения, наполнить и задать товарам: название, описание, картинку, скидочную этикетку, старую цену, вес, объем, калории и многое другое. Всё это можно разделить по дереву категорий и подкатегорий товаров. Транслитерируемый поиск среди заведений и позиций по названиям, категориям и описанию. Помимо всего в филиалах можно настраивать графики работ, способы оплаты, варианты уведомления о новых заказах, чтобы никого не оставить без внимания.</p>
								</Col>
							</Row>
							<Row>
								<Col xs={8} sm={8} md={10} lg={10}>
									<p className="middle-text">Вы можете стать агрегатором или же организовать работу доставки своего заведения, нет никакой разницы - гибкости нашей системы будет достаточно для выполнения этих задач. Разделение прав доступов позволяет очень гибко настроить разрешенные разделы. Отдельная настройка тарифов на стоимость доставки, списаний с курьеров и заведений. Наше программное обеспечение позволяет извлекать прибыль от процесса автоматизации создания заказов и их выполнения без акцентирования внимания на вашу роль в этой цепи.</p>
								</Col>
								<Col xs={4} sm={4} md={2} lg={2}>
									<div className="numCircle">2</div>
								</Col>
							</Row>
							<Row>
								<Col xs={4} sm={4} md={2} lg={2}>
									<div className="numCircle">3</div>
								</Col>
								<Col xs={8} sm={8} md={10} lg={10}>
									<p className="middle-text">Прием на работу курьеров может осуществляться через анкету нашего виджета встраиваемого на вашем сайте, из мобильного приложения для курьеров, либо вручную вами из кабинета. В первых двух случаях курьеры предоставляют о себе полную информацию которую вы захотите получать о желающих трудоустроиться, в последнем - заполнением занимается менеджер при приеме новых сотрудников. Все анкеты появляются в вашем кабинете и будут отображены для рассмотрения в соответствующем разделе.</p>
								</Col>
							</Row>
							<Row>
								<Col xs={8} sm={8} md={10} lg={10}>
									<p className="middle-text">Справделивый расчет маршрута доставки по дорогам. Позволяет максимально точно вычислить стоимость организации получения заказа клиентом, что дает большое преимущество в сравнении с устаревшими понятиями "зон доставки" и их обслуживанием, когда клиент находится близко, то он не будет переплачивать за доставку, а заплатит справедливую цену, если он находится далеко и готов платить, теперь у него будет эта возможность. Расширяя границы обслуживания и предлагая спреведливую цену, вы уйдете на несколько шагов от своих конкурентов.</p>
								</Col>
								<Col xs={4} sm={4} md={2} lg={2}>
									<div className="numCircle">4</div>
								</Col>
							</Row>
							<Row>
								<Col xs={4} sm={4} md={2} lg={2}>
									<div className="numCircle">5</div>
								</Col>
								<Col xs={8} sm={8} md={10} lg={10}>
									<p className="middle-text">Возможность подключения биржи и взаимодействия с другими фирмами огромный плюс в развитии собственного бизнеса, ведь бывают случаи когда не хватает собственного ресурса для доставки заказов или приема звонков, с нами можно подключаться с партнерами и вместе на взаимовыгодных условиях оказывать друг другу помощь, механизмы взаиморасчетов уже годами сформированы на нашей стороне, вам будет не о чем переживать.</p>
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
			{/*</Container>*/}
		</section>;
	}
}