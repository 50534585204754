import React from 'react';
import {Request} from '../../request';
import '../../css/section6.css';
import {
	Container,
	Row,
	Col
} from 'react-bootstrap';
import {BiLoaderAlt} from 'react-icons/bi';
import {
	BsSkype,
	BsWhatsapp
} from 'react-icons/bs';
import {
	MdCall,
	MdEmail
} from 'react-icons/md';

export default class Section6 extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loadPhone: false,
			loadSkype: false,
			loadWhatsapp: false,
			loadEmail: false,
		};
	}

	componentDidMount() {
	}

	getContact(type) {
		let stateLoadKeys = {
			phone: 'loadPhone',
			skype: 'loadSkype',
			whatsapp: 'loadWhatsapp',
			email: 'loadEmail'
		};
		let stateObj = {};
		stateObj[stateLoadKeys[type]] = true;
		this.setState(stateObj, () => {
			Request('/contact', {type: type})
				.then((result) => {
					// console.log('result',result);
					window.location.href = result.data;
				})
				.catch((error) => {
					console.log('error',error);
				})
				.finally(() => {
					let stateObj = {};
					stateObj[stateLoadKeys[type]] = false;
					this.setState(stateObj);
				});
		});
	}

	render() {
		return <section className="section-6" id="contacts">
			<Container>
				<Row>
					<Col md={6}>
						<p className="title">Обратная связь</p>
						<p className="middle-text">Будем рады узнать о вас, познакомиться и приступить к сотрудничеству, ниже реквизиты для обратной связи:</p>
						<Row>
							<Col xs={3}>
								<div onClick={()=>{this.getContact('phone')}} className="contactButton phone">
									{this.state.loadPhone?<BiLoaderAlt className="spin-1s"/>:<MdCall/>}
								</div>
							</Col>
							<Col xs={3}>
								<div onClick={()=>{this.getContact('skype')}} className="contactButton skype">
									{this.state.loadSkype?<BiLoaderAlt className="spin-1s"/>:<BsSkype/>}
								</div>
							</Col>
							<Col xs={3}>
								<div onClick={()=>{this.getContact('whatsapp')}} className="contactButton whatsapp">
									{this.state.loadWhatsapp?<BiLoaderAlt className="spin-1s"/>:<BsWhatsapp/>}
								</div>
							</Col>
							<Col xs={3}>
								<div onClick={()=>{this.getContact('email')}} className="contactButton email">
									{this.state.loadEmail?<BiLoaderAlt className="spin-1s"/>:<MdEmail/>}
								</div>
							</Col>
						</Row>
						{/*<p>Телефон: <a href="tel:+79002828328" style={{color:'#0fb6ed'}}>+79002828328</a></p>
						<p>Skype: <a href="skype:v.kozlovskih?chat" style={{color:'#0fb6ed'}}>v.kozlovskih</a></p>
						<p>Email: <a href="mailto:taxisoft@list.ru" style={{color:'#0fb6ed'}}>taxisoft@list.ru</a></p>*/}
					</Col>
					<Col md={6}>
						<img src="images/contacts.png" className="w100"/>
					</Col>
				</Row>
			</Container>
		</section>;
	}
}