import React from 'react';
import '../../css/section3.css';
import {
	Container,
	Row,
	Col
} from 'react-bootstrap';

export default class Section3 extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
		};
	}

	componentDidMount() {
	}

	render() {
		return <section className="section-3">
			<Container>
				<Row>
					<Col md={6}>
						<div className="d-none d-lg-block" style={{marginTop:'200px'}}></div>
						<p className="title">Легко и просто</p>
						<p className="middle-text">Для минимального старта из оборудования вам будет достаточно смартфона, в нем вы сможете принимать на работу курьеров, заполнять в магазине меню и каталог товаров, узнавать о новых поступивших заказах и обрабатывать их взаимодействуя с клиентами, уведомляя их о ходе выполнения. Аналогичные действия можно совершать на ПК из под любой операционной системы в которой есть браузер.</p>
					</Col>
					<Col md={6}>
						<img src="images/currentOrder.png" className="w100"/>
					</Col>
				</Row>
			</Container>
		</section>;
	}
}