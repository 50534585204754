import { createSlice } from '@reduxjs/toolkit';

export const mainSlice = createSlice({
	name: 'main',
	initialState: {
		clientPhone: '',
		currencyUnicode: '',
		orderDeleteCause: [],
		deliveryPrice: 0,
		deliveryPriceCalculating: false,
		whereDeliveryToDesc: '',
		// whereDeliveryToCoords: null,//(object){x:45, y:38}
		whereDeliveryToObj: null,//Object of search result
		whereDeliveryToEntrance: '',
		whereDeliveryToFloor: '',
		whereDeliveryToFlat: '',
		orderComment: '',
		paymentType: null,
		changeFromBanknoteArr: null,
		changeFromBanknote: null,
		cardToken: null,
		logoutBtn: true,
		taxiUrl: null,
		preLoader: false,
	},
	reducers: {
		setState: (state, action) => {
			if (typeof action.payload !== 'object')
				return;

			Object.keys(action.payload).forEach((v,i) => {
				state[v] = action.payload[v];
			});
		},
	},
});

// Action creators are generated for each case reducer function
export const {setState} = mainSlice.actions;

export default mainSlice.reducer;