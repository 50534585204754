import React from 'react';
import '../../css/section5.css';
import {
	Container,
	Row,
	Col
} from 'react-bootstrap';

export default class Section5 extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
		};
	}

	componentDidMount() {
	}

	render() {
		return <section className="section-5" id="pricing">
			<Container>
				<Row>
					<Col md={6}>
						<p className="title" style={{fontSize:'2.7rem'}}>Ценообразование</p>
						<p className="d-md-none d-sm-block" style={{fontSize:'4rem',color:'#0fb6ed'}}>1% от чека</p>
						<p className="d-none d-md-block" style={{fontSize:'7rem',color:'#0fb6ed'}}>1% от чека</p>
						<p className="middle-text" style={{fontSize:'.7rem',color:'#bbb',margin:'-2.25rem .6rem 2.25rem'}}>* - не менее 10 000 рублей в месяц</p>
						<p className="middle-text">За использование программы мы берем <span style={{color: '#db545a'}}>1%</span> от чека успешно выполненного заказа.</p>
						<p className="middle-text">Мы очень заинтересованы в Вашем успехе, по этому предоставляем <span style={{color:'#db545a'}}>полный</span> доступ ко всем инструментам нашей системы автоматизации приема, обработки и выполнения заказов на доставку.</p>
						<p className="middle-text" style={{margin:0,color:'#bebeff'}}>В минимальный ежемесячный платеж входит:</p>
						<ul>
							<li>Полный доступ к возможностям системы</li>
							<li>Бесплатные обновления и доработки</li>
							<li>Поддержка 24/7</li>
							<li>Выделенный сервер</li>
						</ul>
					</Col>
					<Col md={6}>
						<img src="images/workSpace.png" className="w100"/>
					</Col>
				</Row>
			</Container>
		</section>;
	}
}