import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import '../css/header.css';
import {
	Container,
	Nav,
	Navbar,
	NavDropdown
} from 'react-bootstrap';

class Header extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			redirectTo: null,
		};
	}

	componentDidMount()
	{
	}

	render() {
		if (this.state.redirectTo)
			return <Redirect to={this.state.redirectTo}/>;

		// return <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
		return <Navbar collapseOnSelect expand="lg" fixed="top">
			<Container>
				<Navbar.Brand href='#top'>INLO</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
					<Nav className="me-auto">
						<Nav.Link href="#intro">Вступление</Nav.Link>
						<Nav.Link href="#features">Возможности</Nav.Link>
						<Nav.Link href="#pricing">Цены</Nav.Link>
						{/*<NavDropdown title="Модули" id="collasible-nav-dropdown">
							<NavDropdown.Item href="#action/3.1">Расчет стоимости доставки</NavDropdown.Item>
							<NavDropdown.Item href="#action/3.2">
								Списание с заведений
							</NavDropdown.Item>
							<NavDropdown.Item href="#action/3.3">Мобильные приложения</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item href="#action/3.4">
								Оплата картами
							</NavDropdown.Item>
						</NavDropdown>*/}
					</Nav>
					<Nav>
						{/*<Nav.Link href="#deets">О нас</Nav.Link>*/}
						<Nav.Link eventKey={2} href="#contacts">
							Контакты
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>;
	}
}

const mapStateToProps = (state) => {
	return {

	}
};
const mapDispatchToProps = (dispatch) => {
	return {
		setStateMain: async(obj,callBack) => {
			await dispatch({type: 'main/setState', payload: obj});
			if (typeof callBack === 'function')
				callBack();
		},
		setStateHeader: async(obj,callBack) => {
			await dispatch({type: 'header/setState', payload: obj});
			if (typeof callBack === 'function')
				callBack();
		}
	}
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);