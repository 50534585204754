import React from 'react';
import '../../css/section1.css';
import {
	Container,
	Row,
	Col,
	Button
} from 'react-bootstrap';

export default class Section1 extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
		};
	}

	componentDidMount() {
	}

	render() {
		return <section className="section-1" id="top">
			<Container>
				<Row>
					<Col md={6}>
						<div className="d-none d-sm-block" style={{marginTop:'200px'}}></div>
						<h1 className="title">INLO</h1>
						<p className="large-text">Проводник вашего бизнеса в мир доставки</p>
						<Button variant="danger" href="#intro">Подробнее</Button>
					</Col>
					<Col md={6}>
						<img src="images/insideBranch.png" className="w100 mb50"/>
					</Col>
				</Row>
			</Container>
		</section>;
	}
}