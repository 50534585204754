import {createSlice} from '@reduxjs/toolkit';

export const headerSlice = createSlice({
	name: 'header',
	initialState: {
		searchAdressItems: [],
		searchAdressOpened: false,
		menuOpen: false
	},
	reducers: {
		setState: (state, action) => {
			if (typeof action.payload !== 'object')
				return;

			Object.keys(action.payload).forEach((v,i) => {
				state[v] = action.payload[v];
			});
		},
		clearSearchAdress: (state, action) => {
			let initialStateObj = headerSlice.getInitialState();
			Object.keys(initialStateObj).map((v,i) => {
				state[v] = initialStateObj[v];
				return true;
			});
		},
	},
});

export default headerSlice.reducer;