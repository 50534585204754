import React from 'react';
import '../css/copyright.css';
import {
	Container,
	Row,
	Col
} from 'react-bootstrap';

export default class Copyright extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
		};
	}

	componentDidMount() {
	}

	render() {
		return <section className="copyright">
			<Container>
				<Row>
					<Col md={12}>
						<p className="middle-text text-center" style={{margin:0,color:'#7b7b7b'}}>INLO Team © {new Date().getFullYear()}</p>
					</Col>
				</Row>
			</Container>
		</section>;
	}
}