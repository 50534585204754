import React from 'react';
import Header from './header';
import Section1 from './sections/section1';
import Section2 from './sections/section2';
import Section3 from './sections/section3';
import Section4 from './sections/section4';
import Section5 from './sections/section5';
import Section6 from './sections/section6';
import Copyright from './copyright';

export default class Home extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
		};
	}

	componentDidMount() {
	}

	render() {
		return <React.Fragment>
			<Header history={this.props.history}/>
			<Section1/>
			<Section2/>
			<Section3/>
			<Section4/>
			<Section5/>
			<Section6/>
			<Copyright/>
		</React.Fragment>
	}
}